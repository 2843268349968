import PropTypes from 'prop-types';
import { Button, Icon } from 'bv-components';
import { t } from 'bv-i18n';

import connect from 'MainSettings/helpers/connect';
import withSection from 'MainSettings/hocs/with_section';
import { getEntryFromState } from 'MainSettings/selectors';

const TwoFactorAuthStatus = ({ active }) => {
  const status = active ? 'enabled' : 'disabled';

  return (
    <>
      <Icon id={`lock-${status}`} className="settings-twofa-icon" />
      <span>
        {t(`javascript.twofa.settings.${status}`)}
      </span>
      <div className="settings-button-wrapper">
        <Button
          noClass
          className="settings-twofa__toggle-button"
          href="/preferences/verify_password_twofactor"
          label={t(`javascript.twofa.settings.${status}_title`)}
          type="settings-view-button"
        />
      </div>
    </>
  );
};

TwoFactorAuthStatus.propTypes = {
  active: PropTypes.bool.isRequired,
};

const mapStateToProps = getEntryFromState('twoFAStatus');

export default connect(mapStateToProps)(
  withSection({
    title: 'javascript.twofa.navigation_title.twofa',
    className: 'twofa-settings-section',
  })(TwoFactorAuthStatus),
);
